import { useState , useEffect} from 'react'
import TopBar from 'components/LayoutComponents/TopBar/index'
import Menu from 'components/LayoutComponents/Menu/MenuLeft/index copy'
import { Layout } from '@surfside/shoreline-ui'
import Breadcrumbs from 'components/LayoutComponents/Breadcrumbs'
import {useLocation} from 'react-router-dom'

const PATHLIST = ['/create/source', '/dsp/create-campaign','/create/audience', '/create/destination']

const MainLayout = ({children}) => {
  const [isCreate, setIsCreate] =  useState(false)
  const location = useLocation()
  useEffect(()=>{
    if(PATHLIST.includes(location.pathname)){
      setIsCreate(true)
    }else{
      setIsCreate(false)
    }

  },[location])

  return (
    <Layout Navbar={Menu} Header={TopBar} BreadCrumbs={Breadcrumbs} Main={()=>{return children}} isCreate={isCreate}/>
  )
}
export default MainLayout
