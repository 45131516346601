import React, { createContext, useState, useEffect } from 'react';

export const BreadcrumbContext = createContext(); // gets ability from provider

export const BreadcrumbProvider = (props) => {
  const updateBreadcrumbHistory = (value, link, isRoot, location) => {
    let brCrbStr = localStorage.getItem('breadcrumbHistory') || '{}'
    let brCrbObj = JSON.parse(brCrbStr)
    if (isRoot) {
      brCrbObj = {
        [link]: [{ title: 'Home', path: '/' }, { title: value, path: `${link}` }]
      }
    } else {
      const existingHash = location.pathname
      if (existingHash) {
        brCrbObj[link] = [...brCrbObj[existingHash], { title: value, path: `${link}` }]
      } else {
        brCrbObj[link] = [{ title: 'Home', path: '/' }, { title: value, path: `${link}` }]
      }
    }
    localStorage.setItem('breadcrumbHistory', JSON.stringify(brCrbObj))
  }

  return <BreadcrumbContext.Provider value={{ update: updateBreadcrumbHistory }} >
    {props.children}
  </BreadcrumbContext.Provider>

}