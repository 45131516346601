import React, { useState, useEffect, useContext } from 'react'
import { AbilityContext } from 'casl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import { subject } from '@casl/ability'
import store from 'store'
import { Scrollbars } from 'react-custom-scrollbars'
import _ from 'lodash'
import styles from './style.module.scss'
import SurfsideIcon from '../../../Icons/surfsideIcon'
import HamburgerIcon from '../../../Icons/hamburgerIcon'
import { GET_LEFT_MENU_OPEN_STATE } from '../../../../redux/menu/sagas'
import GenerateMenuItems from './GenerateMenu'
import { useDeepCompareEffect } from 'hooks/deep-compare-effect'
const { Sider } = Layout

const authorize = (user, items) => {
  const { 'http://surfside/role': role } = user
  return items.filter((item) => item.roles === undefined || item.roles.includes(role))
}

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu.menuLeftData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isSettingsOpen: settings.isSettingsOpen,
  isDarkTheme: settings.isDarkTheme,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  user: user,
})


const MenuLeft = (props) => {
  const [ability] = useContext(AbilityContext)
  const { dispatch, isMenuCollapsed, isMobileView, isLightTheme, isSettingsOpen, menuData } = props;
  const [state, setState] = useState({
    selectedKeys: store.get('app.menu.selectedKeys') || [],
    openedKeys: store.get('app.mednu.openedKeys') || [],
  })

  const [secureMenuData, setSecureMenuData] = useState([]);
  const [collapsed, setCollapsed] = useState(store.get('app.menu.collapsed') === undefined ?
    true :
    store.get('app.menu.collapsed'))



  useEffect(() => {
    setSelectedKeys(props)
  }, [])

  const menuTypeChange = (newProps) => {
    if (newProps.isMenuCollapsed && !newProps.isMobileView) {
      setState({
        ...state,
        openedKeys: [],
      })
    }
    setSelectedKeys(newProps)
  }


  useDeepCompareEffect(() => {
    const _secureMenuData = []
    secureMenu(menuData, _secureMenuData)
    setSecureMenuData(_secureMenuData)
    window.ability = ability
  }, [menuData, ability])


  const secureMenu = (menuData = [], _secureMenuData = []) => {
    for (let i = 0; i < menuData.length; i++) {
      if (menuData[i]) {
        const { $id, title, $name, $skip } = menuData[i];
        // console.log("id, title name", $id, $name || title, ability.can("VIEW", subject($name || title, { id: $id })))
        if ($skip || ability?.can("VIEW", subject($name || title, { id: $id }))) {
          const _newsecureData = { ...menuData[i] }
          _secureMenuData.push(_newsecureData);
          if (menuData[i].children) {
            _newsecureData.children = []
            secureMenu(menuData[i].children, _newsecureData.children)
          }
        }
      }

    }
  }

  useEffect(() => {
    menuTypeChange(props)
  }, [isMenuCollapsed, isMobileView])


  const setSelectedKeys = (props) => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = _.find(flattenItems(menuData, 'children'), [
      'url',
      props.location.pathname,
    ])
    setState({
      ...state,
      selectedKeys: selectedItem ? [selectedItem.key] : [],
    })
  }

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }

    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
  }

  const onOpenChange = (openedKeys) => {
    store.set('app.menu.openedKeys', openedKeys)
    setState({
      ...state,
      openedKeys,
    })
  }

  const handleClick = (e) => {
    store.set('app.menu.selectedKeys', [e.key])
    // custom action on settings menu item
    if (e.key === 'settings') {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isSettingsOpen',
          value: !isSettingsOpen,
        },
      })
      return
    }
    setState({
      ...state,
      selectedKeys: [e.key],
      // openKeys: e.keyPath,
    })
  }

  useEffect(() => {
    store.set('app.menu.collapsed', collapsed)
    dispatch(GET_LEFT_MENU_OPEN_STATE(!collapsed))
  }, [collapsed])

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  const menuSettings = {
    width: 256,
    collapsible: false,
    collapsed: isMobileView ? false : isMenuCollapsed,
    breakpoint: isMobileView ? undefined : 'lg',
    onCollapse,
  }

  return (
    <Sider
      {...menuSettings}
      trigger={null}
      className={isLightTheme ? `${styles.menu} ${styles.light}` : styles.menu}
      collapsed={collapsed}
    >
      <Scrollbars
        className={isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
        renderThumbVertical={({ style, ...props }) => (
          <div
            {...props}
            style={{
              ...style,
              width: '4px',
              borderRadius: 'inherit',
              backgroundColor: '#c5cdd2',
              left: '1px',
            }}
          />
        )}
        autoHide
      >
        <button type="button" className={styles.hamburger} onClick={toggle}>
          {/* {SurfsideIcon()} */}
            {HamburgerIcon()}
        </button>
        <Menu
          theme={isLightTheme ? 'light' : 'dark'}
          onClick={handleClick}
          onOpenChange={onOpenChange}
          mode="vertical"
          className={styles.navigation}
        >
          {GenerateMenuItems({ menuData: authorize(props.user, secureMenuData) })}
        </Menu>
      </Scrollbars>
    </Sider>
  )
}



export default withRouter(connect(mapStateToProps)(MenuLeft))


// async function secureMenu(user = {}, menuData, callback) {
//   if (user.sub) {
//     const userId = user.sub.split('|')[1]
//     const path = `dmp/userroles/auth0/id/${userId}?casl=true`;
//     console.log('surfsideApi', surfsideApi)
//     surfsideApi({ method: 'GET', path }, callback)
//     return { userId, menuData }
//   }
//   return {}
// }
/*
<GenerateMenuItems menuData={this.props.menuData}/>
 */
