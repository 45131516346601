import React, { useState, useEffect, useContext } from 'react'
import { AbilityContext } from 'casl'
import { subject } from '@casl/ability'
import { useDeepCompareEffect } from 'hooks/deep-compare-effect'
import {menuData, footerData } from './MenuData.tsx'
import { Navbar } from '@surfside/shoreline-ui'
import { BreadcrumbContext } from 'Breadcrumb'
import { useHistory} from 'react-router-dom'

const MenuLeft = ({opened, handlers}) => {
  const [ability] = useContext(AbilityContext)
  const breadcrumb = useContext(BreadcrumbContext)
  const [secureMenuData, setSecureMenuData] = useState([]);
  const [secureFooterData, setSecureFooterData] = useState([]);
  const history = useHistory()

  const onNavigation = (url, isCreate)=>{   
    history.push(url, {openDrawer: isCreate})
  }
  useDeepCompareEffect(() => {
    const _secureMenuData = []
    const _secureFooterData = []
    secureMenu(menuData, _secureMenuData)
    setSecureMenuData(_secureMenuData)
    secureMenu(footerData, _secureFooterData)
    setSecureFooterData(_secureFooterData)
    window.ability = ability
  }, [menuData, ability])


  const secureMenu = (menuData = [], _secureMenuData = []) => {
    for (let i = 0; i < menuData.length; i++) {
      if (menuData[i]) {
        const { $id, title, $name, $skip } = menuData[i];
        // console.log("id, title name", $id, $name || title, ability.can("VIEW", subject($name || title, { id: $id })))
        if ($skip || ability?.can("VIEW", subject($name || title, { id: $id }))) {
          const _newsecureData = { ...menuData[i] }
          _secureMenuData.push(_newsecureData);
          if (menuData[i].children) {
            _newsecureData.children = []
            secureMenu(menuData[i].children, _newsecureData.children)
          }
          if (menuData[i].createChildren){
            _newsecureData.createChildren = []
            secureMenu(menuData[i].createChildren, _newsecureData.createChildren)
          }
        }
      }
    }
  }

  return <Navbar opened={opened} handlers={handlers} menuData={secureMenuData} footerData={secureFooterData} updateBreadCrumbs={breadcrumb.update} onNavigation={onNavigation}/>
}



export default MenuLeft
