// src/contexts/AccountContext.js

import React, { createContext, useContext, useState} from 'react';
import Cookies from 'js-cookie';

const AccountContext = createContext();

export const useAccount = () => useContext(AccountContext);


export const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState(Cookies.get('account'));


  const handleSetAccount = (newAccount) => {
    Cookies.set('account', newAccount);
    console.log("setting an account", newAccount)
    setAccount(newAccount);
   // window.location.reload()
  };  

  return (
    <AccountContext.Provider value={{ account, setAccount: handleSetAccount}}>
      {children}
    </AccountContext.Provider>
  );
};
