// src/contexts/AccountContext.js

import React, { createContext, useContext, useState} from 'react';

const UserContext = createContext<{ user: User,  setUser: (user: User) => void }>({user:{
  id: '',
  name: '',
  role: '',
  email: '',
  avatar: '',
  authorized: false,
  loading: false,
},setUser:()=>void 0
});

interface User {
  id: string,
  name:string,
  role:string,
  email: string,
  avatar: string,
  authorized: boolean,
  loading: boolean,
}

export const useUser = () => useContext(UserContext);


export const UserProvider = ({ children } : { children: React.ReactNode }) => {
  const [user, setUser] = useState<User>({
    id: '',
    name: '',
    role: '',
    email: '',
    avatar: '',
    authorized: false,
    loading: false,
  });

  return (<UserContext.Provider value={{ user, setUser: setUser}}>
      {children}
    </UserContext.Provider>
  )
};
