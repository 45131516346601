import React, { useContext } from 'react'
import { Menu } from 'antd'
import styles from '../style.module.scss'
import { Link, useLocation } from 'react-router-dom'
import { BreadcrumbContext } from 'Breadcrumb'

function hashCode(string) {
  var hash = 0;
  for (var i = 0; i < string.length; i++) {
    var code = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + code;
    hash = hash & hash;
  }
  return hash.toString();
}

const { SubMenu, Divider } = Menu

const GenerateMenuItems = props => {
  const breadcrumb = useContext(BreadcrumbContext)
  const location = useLocation()
  const { menuData = [], ...childrenProps } = props

  const handleClick = (value, location) => {
    breadcrumb.update(value, location, true)
  }

  const generateItem = item => {
    const { key, title, url, icon, disabled, pro, staticIcon } = item
    if (item.divider) {
      return <Divider key={Math.random()} />
    }

    const hash = hashCode(location.pathname)

    if (item.url) {
      return (
        <Menu.Item key={key} disabled={disabled}>
          {item.target ? (
            <a href={url} target={item.target} rel="noopener noreferrer">
              {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
              <span className={styles.title}>{title}</span>
            </a>
          ) : (
            <Link to={`${url}`} onClick={() => handleClick(title, url, hash)}>
              {staticIcon && staticIcon()}
              <span className={styles.title}>{title}</span>
            </Link>
          )}
        </Menu.Item>
      )
    }
    return (
      <Menu.Item key={key} disabled={disabled} {...childrenProps}>
        <span className={styles.flexWrapper}>
          {staticIcon && staticIcon()}
          <span className={styles.title}>{title}</span>
        </span>
      </Menu.Item>
    )
  }

  const generateSubmenu = items =>
    items.map(menuItem => {
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
            <span className={styles.title}>{menuItem.title}</span>
          </span>
        )
        if (menuItem.key === 'User Management') {
          return (
            <SubMenu title={subMenuTitle} key={menuItem.key} popupOffset={[4, -148]}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          )
        } else {
          return (
            <SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          )
        }
      }
      return generateItem(menuItem)
    })

  const MenuItemView = menuItemProps => {
    const { menuItem } = menuItemProps
    if (menuItem.children) {
      const subMenuTitle = (
        <span key={menuItem.key}>
          {menuItem.staticIcon && menuItem.staticIcon()}
          <span className={styles.title}>{menuItem.title}</span>
        </span>
      )
      if (menuItem.key === 'settings') {
        return (
          <SubMenu title={subMenuTitle} key={menuItem.key} popupOffset={[4, -137]}>
            {generateSubmenu(menuItem.children)}
          </SubMenu>
        )
      } else {
        return (
          <SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </SubMenu>
        )
      }
    }

    return generateItem(menuItem)
  }

  // const AuthorizedMenu = <AuthorizeMap map={menuData} />
  return menuData.map(menuItem => MenuItemView({ menuItem: menuItem }))
}

export default GenerateMenuItems
