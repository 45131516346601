export interface MenuDataType {
  title: string,
  roles?: string[],
  key?: string,
  icon?: string,
  url?: string,
  $id?: string,
  $name?: string
  createChildren?: MenuDataType[],
  children?: MenuDataType[],
  $skip?: boolean,
  $context?: string,
  target?: string,
  isDrawerOpen?: boolean
}

export const menuData: MenuDataType[] = [
  //Home - only url on parent
  {
    title: 'Home',
    roles: ['admin', 'client'],
    key: 'SurfsideHome',
    url: '/home',
    icon: 'dashboard',
    $id: '/menu/home',
  },
  //Sources Audiences Destinations
  //Url on parent + url on Create only Child
  {
    title: 'Sources',
    roles: ['admin', 'client'],
    key: 'viewAllSource',
    icon: 'source',
    url: '/all/sources',
    $id: '/menu/all/sources',
    createChildren: [
      {
        title: 'Source',
        key: 'createSource',
        $id: '/menu/create/source',
        $name: 'Source',
        url: '/create/source',
        isDrawerOpen: false
      },
    ],
  },
  {
    title: 'Audiences',
    roles: ['admin'],
    key: 'audienced',
    icon: 'audiences',
    url: '/all/audiences',
    $id: '/menu/all/audiences',
    createChildren: [
      {
        key: 'createAudience',
        title: 'Audience',
        $id: '/menu/create/audience',
        $name: 'Audience',
        url: '/create/audience',
        isDrawerOpen: false
      },
    ],
  },
  {
    title: 'Destinations',
    roles: ['admin'],
    key: 'viewAllDestinations',
    icon: 'destinations',
    url: '/all/destinations',
    $id: '/menu/all/destinations',
    createChildren: [
      {
        key: 'createDestination',
        title: 'Destination',
        $id: '/menu/create/destination',
        $name: 'Destination',
        url: '/create/destination',
        isDrawerOpen: false
      },
    ],
  },
  //Reporting
  //Only Url on Parents
  {
    title: 'Reporting',
    key: 'viewReports',
    icon: 'reporting',
    url: '/reports',
    $id: '/menu/reports',
    $name: 'Reports',
    createChildren: [
      {
        key: 'createReportingNew',
        title: 'Reporting',
        $id: '/menu/create/reporting',
        url: '/reports',
        isDrawerOpen: true,
        $skip:true
      },
    ],
  },
  //Places documentation
  //Only Url on children, no parent
  {
    title: 'Places',
    key: 'viewPlaces',
    $name: 'Places',
    $id: '/menu/places',
    icon: 'places',
    children: [
      {
        key: 'viewPointsOfInterest',
        title: 'Points of Interest',
        url: '/all/poi',
        $id: '/menu/all/poi',
        $name: 'Points of Interest',
      },
      {
        key: 'viewRetailInsights',
        title: 'Retail Insights',
        url: '/all/retail',
        $id: '/menu/all/retail',
        $name: 'Retail Insights',
      },
    ],
  },
  //AdManager Inventory
  //Only Url on Childrens, not on parents, createChildren need to map back to children Url with same title
  {
    title: 'Ads Manager',
    roles: ['admin'],
    key: 'viewAdLauncher',
    icon: 'adManager',
    $name: 'AdLauncher',
    $id: '/menu/dsp',
    children: [
      {
        key: 'viewDSPCampaigns',
        title: 'Campaigns',
        url: '/dsp/campaigns',
        $id: '/menu/dsp/campaigns',
        $name: 'Campaigns',
      },
      {
        key: 'viewDSPStrategies',
        title: 'Strategies',
        $skip: true,
        url: '/dsp/strategies',
        $id: '/menu/dsp/strategies',
        $name: 'Strategies',
      },
      {
        key: 'viewDSPPlacements',
        title: 'Placements',
        url: '/dsp/placements',
        $id: '/menu/dsp/placements',
        $name: 'Placements',
      },
      {
        key: 'viewDSPCreative',
        title: 'Creative',
        url: '/dsp/creative',
        $id: '/menu/dsp/creative',
        $name: 'Creative',
      },
      {
        key: 'viewDSPAdSets',
        title: 'Ad Sets',
        url: '/dsp/adSets',
        $id: '/menu/dsp/adsets',
        $name: 'AdSets',
      },
      {
        key: 'viewDSPTargeting',
        title: 'Targeting',
        url: '/dsp/targeting',
        $id: '/menu/dsp/targeting',
        $name: 'Targeting',
      },
      {
        key: 'viewDSPRulesets',
        title: 'Rulesets',
        url: '/dsp/rulesets',
        $id: '/menu/dsp/rulesets',
        $name: 'Rulesets',
      },
      {
        key: 'viewDSPLists',
        title: 'Lists',
        url: '/dsp/lists',
        $id: '/menu/dsp/lists',
        $name: 'Lists',
      },
    ],
    createChildren: [
      {
        title: 'Campaigns',
        key: 'createCampaignNew',
        $id: '/menu/create/campaign',
        $name: 'Campaign',
        $skip: true,
        url: '/dsp/create-campaign',
      },

      {
        key: 'createStrategyNew',
        title: 'Strategies',
        $skip: true,
        $id: '/menu/create/strategies',
        $name: 'Strategies',
        url: '/dsp/strategies',
        isDrawerOpen: true
      },
      {
        key: 'createPlacementNew',
        title: 'Placements',
        $id: '/menu/create/placements',
        $name: 'Placements',
        url: '/dsp/placements',
        isDrawerOpen: true,
        $skip:true
      },
      {
        key: 'createCreativeNew',
        title: 'Creative',
        $id: '/menu/create/creative',
        $name: 'Creative',
        url: '/dsp/creative',
        isDrawerOpen: true,
        $skip:true
      },
      {
        key: 'createAdSetNew',
        title: 'Ad Sets',
        $id: '/menu/create/adsets',
        $name: 'AdSets',
        url: '/dsp/adsets',
        isDrawerOpen: true,
        $skip:true
      },
      {
        key: 'createTargetingNew',
        title: 'Targeting',
        $id: '/menu/create/targeting',
        $name: 'Targeting',
        url: '/dsp/targeting',
        isDrawerOpen: true,
        $skip:true
      },
      {
        key: 'createRulesetsNew',
        title: 'Rulesets',
        $id: '/menu/create/rulesets',
        $name: 'Rulesets',
        url: '/dsp/rulesets',
        isDrawerOpen: true,
        $skip:true
      },
      {
        key: 'createListsNew',
        title: 'Lists',
        $id: '/menu/create/lists',
        $name: 'Lists',
        url: '/dsp/lists',
        isDrawerOpen: true,
        $skip:true
      },
    ],
  },
  {
    title: 'Inventory',
    key: 'inventory',
    icon: 'inventory',
    $id: '/menu/inventory',
    children: [
      {
        key: 'channels',
        title: 'Channels',
        url: '/inventory/channels',
        $id: '/menu/inventory/channels',
        $skip: true,
      },
      {
        key: 'sites',
        title: 'Sites',
        url: '/inventory/sites',
        $id: '/menu/inventory/sites',
        $skip: true,
      },
      {
        key: 'zones',
        title: 'Zones',
        url: '/inventory/zones',
        $id: '/menu/inventory/zones',
        $skip: true,
      },
      {
        $skip: true,
        key: 'adtypes',
        title: 'Ad Types',
        url: '/inventory/adtypes',
        $id: '/menu/inventory/adtypes',
      },
      {
        $skip: true,
        key: 'products',
        title: 'Products',
        url: '/inventory/products',
        $id: '/menu/inventory/products',
      }
    ],
    createChildren: [
      {
        key: 'createChannelNew',
        title: 'Channels',
        $id: '/menu/create/inventory/channels',
        url: '/inventory/channels',
        isDrawerOpen: true,
        $skip: true,
      },
      {
        key: 'createSiteNew',
        title: 'Sites',
        $id: '/menu/create/inventory/sites',
        url: '/inventory/sites',
        isDrawerOpen: true,
        $skip: true,
      },
      {
        key: 'createZoneNew',
        title: 'Zones',
        $id: '/menu/create/inventory/zones',
        url: '/inventory/zones',
        isDrawerOpen: true,
        $skip: true,
      },
      {
        key: 'createAdtypesNew',
        title: 'Ad Types',
        $id: '/menu/create/inventory/adtypes',
        url: '/inventory/adtypes',
        isDrawerOpen: true,
        $skip: true,
      },
      {
        key: 'createProducts',
        title: 'Products',
        $id: '/menu/create/inventory/products',
        url: '/inventory/products',
        isDrawerOpen: true,
        $skip: true,
      }
    ],
  },
];

export const footerData: MenuDataType[] = [
  {
    title: 'Documentation',
    key: 'Documentation',
    $skip: true,
    $id: '/menu/documentation',
    icon: 'help',
    children: [
      {
        $skip: true,
        key: 'Api',
        title: 'User Guide',
        url: 'https://docs.surfside.io',
        target: '_blank',
        $id: '/menu/docs/userguide',
      },
      {
        $skip: true,
        key: 'Api',
        title: 'API Docs',
        url: '/docs/api',
        $id: '/menu/docs/api',
      },
    ],
  },
  {
    title: 'Settings',
    key: 'settings',
    $id: '/menu/settings',
    icon: 'settings',
    children: [
      {
        $skip: true,
        key: 'account',
        title: 'Account',
        $id: '/menu/settings/account',
        children: [
          {
            $skip: true,
            key: 'Plans & Billing',
            title: 'Plans & Billing',
            url: '/account/overview',
            $id: '/menu/settings/account/plansandbilling',
          },
          {
            $skip: true,
            key: 'apiKeys',
            title: 'API Keys',
            url: '/admin/apikeys',
            $id: '/menu/settings/account/apikeys',
          },
          {
            $skip: true,
            key: 'Credential Manager',
            title: 'Connections',
            url: '/credentials',
            $name: 'CredentialManager',
            $id: '/menu/settings/account/credentials',
          },
          {
            $skip: true,
            key: 'advertisers',
            title: 'Advertisers',
            url: '/advertisers',
            $id: 'menu/settings/account/advertisers',
          },
        ],
      },
      {
        key: 'Tags',
        title: 'Tags',
        url: '/tags',
        $name: 'Tags',
        $id: '/component/tags',
        $context: '/component',
        $skip: true,
      },
      {
        key: 'Location',
        title: 'Location Lists',
        $id: '/menu/location',
        $name: 'Location',
        children: [
          {
            key: 'Attribution',
            title: 'Attribution',
            url: '/attribution',
            $id: '/menu/location/attribution',
            $name: 'Attribution',
          },
          {
            key: 'Points of Interest',
            title: 'Points of Interest',
            url: '/location/poi',
            $name: 'PointsOfInterest',
            $id: '/menu/location/poi',
          },
        ],
      },
      {
        key: 'User Management',
        title: 'Permissions',
        $name: 'UserManagement',
        $id: '/menu/usermanagement',
        children: [
          {
            key: 'Account Management',
            title: 'Accounts',
            url: '/accounts',
            $name: 'AccountManagement',
            $id: '/menu/accounts',
          },
          {
            key: 'users',
            title: 'Users',
            url: '/usermanagement/users',
            $id: '/menu/usermanagement/users',
          },
          {
            key: 'roles',
            title: 'Roles',
            url: '/usermanagement/roles',
            $id: '/menu/usermanagement/roles',
          },
          {
            key: 'features',
            title: 'Features',
            url: '/usermanagement/features',
            $id: '/menu/usermanagement/features',
          },
          {
            key: 'privileges',
            title: 'Privileges',
            url: '/usermanagement/privileges',
            $id: '/menu/usermanagement/privileges',
          },
          {
            key: 'quotas',
            title: 'Quotas',
            url: '/usermanagement/quotas',
            $id: '/menu/usermanagement/quotas',
          },
          {
            key: 'apirules',
            title: 'API Rules',
            url: '/usermanagement/api-rules',
            $id: '/menu/usermanagement/api-rules',
          },
        ],
      },
      {
        key: 'Metrics',
        title: 'Metrics',
        $name: 'Metrics',
        $id: '/menu/metrics',
        children: [
          {
            key: 'Usage',
            title: 'Usage',
            url: '/metrics/usage',
            $id: '/menu/metrics/usage',
            $name: 'Usage',
          },
          {
            key: 'Bidder',
            title: 'Bidder',
            url: '/metrics/bidder',
            $id: '/menu/metrics/bidder',
            $name: 'Bidder',
          },
          {
            key: 'Reasons',
            title: 'Reasons',
            url: '/metrics/reasons',
            $id: '/menu/metrics/reasons',
            $name: 'Reasons',
          },
        ],
      },
    ],
  },
];
