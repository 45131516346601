import { useState, useContext, useEffect } from 'react'
import { Header } from '@surfside/shoreline-ui'
import { useAuth0 } from '../../../auth0'
import { useAccount } from '../../../AccountProvider'
import { ServiceContext } from 'service-context'
import Cookies from 'js-cookie'
import {useUser} from '../../../UserProvider'

const TopBar = () => {
  const [items, setItems] = useState([])
  const { account, setAccount } = useAccount()
  const {user, setUser} = useUser()
  const { accountService } = useContext(ServiceContext)
  const onChange = (value) => {
    console.log('onChangeAccount', value)
    setAccount(value)
  }

  useEffect(() => {
    accountService
      .getAccounts()
      .then((data) => {
        setItems(
          data.map((d) => {
            return { label: d.accountName, value: d.accountId }
          }),
        )
      })
      .catch((e) => {
        console.error(e)
      })
  }, [])

  const { logout } = useAuth0()

  const handleLogout = () => {
   console.log("logout", user)
    setUser({
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    })
    logout({ returnTo: window.location.origin })
    Cookies.remove("auth0-data")
    Cookies.remove("auth0-jwt")
  }

  return (
    <Header
      handleLogout={handleLogout}
      userData={{
        name: user?.nickname,
        email: user?.email,
      }}
      accountData={items}
      onChange={onChange}
      account={account}
      defaultValue={Cookies.get('account')}
    />
  )
}

export default TopBar
