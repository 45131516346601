import { useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import { BreadCrumbs } from '@surfside/shoreline-ui'

const Breadcrumbs = () => {
  let location = useLocation()
  const [breadcrumbState, setBreadcrumbState] = useState([{ title: 'Home', path: '/' }])

  useEffect(() => {
    const currBreadcrumbs = JSON.parse(localStorage.getItem('breadcrumbHistory') || '{}')
    const hash = location.pathname
    if (currBreadcrumbs && currBreadcrumbs[hash] && hash === '/home') {
      setBreadcrumbState([{ title: 'Home', path: '/' }])
    } else if (currBreadcrumbs && currBreadcrumbs[hash]) {
      setBreadcrumbState(currBreadcrumbs[hash])
    } else {
      setBreadcrumbState([{ title: 'Home', path: '/' }])
    }
  }, [location])



  return (
      <BreadCrumbs items={breadcrumbState.slice(0,-1)} />
  )
}

export default Breadcrumbs
